import apiTool from '@/command/apiTool'
import { getAction, postAction } from '@/command/netTool'

let expressListArr = []
async function onFetchInit(data) {
  const [expressList] = await Promise.all(['/api/base/express/list'].map(e => getAction(e)))
  let a = expressList.data.map(e => {
    return { ...e, value: e.name }
  })
  expressListArr = a
  return {
    form: data
  }
}

function onOpen({ update, form = {} } = {}) {
  apiTool.showDrawer({
    title: '快递配置',
    formData: { ...form },
    width: '600px',
    form: [
      {
        name: '快递名称',
        key: 'deliveryName',
        type: 'select',
        typeData: expressListArr,
        props: {
          showSearch: true
        }
      }
    ],
    success: ({ data, setHidden }) => {
      let arr = expressListArr.filter(i => i.name == data.deliveryName)[0]
      let parmas = {
        ...data,
        deliveryCode: arr.code,
        deliveryName: arr.name
      }
      update(parmas).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function createForm({ create }) {
  apiTool.showDrawer({
    title: '快递配置',
    formData: {},
    width: '600px',
    form: [
      {
        name: '快递名称',
        key: 'deliveryName',
        type: 'select',
        typeData: expressListArr,
        props: {
          showSearch: true
        }
      }
    ],
    success: ({ data, setHidden }) => {
      let arr = expressListArr.filter(i => i.name == data.deliveryName)[0]
      let params = {
        deliveryCode: arr.code,
        deliveryName: arr.name
      }
      create(params).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function delData({ del, form = {} } = {}) {
  del(form)
}

export default ({ update, del, create }) => {
  return {
    url: '/config/farmConfigDelivery/list',
    edit: '/config/farmConfigDelivery/update',
    del: '/config/farmConfigDelivery/delete/',
    create: '/config/farmConfigDelivery/add',
    type: 'table',
    onFetchInit,
    props: {
      showFootButton: true,
      on: {
        handleAddData: () => createForm({ create })
      },
      columns: [
        {
          dataIndex: 'deliveryName',
          align: 'left'
        },
        {
          dataIndex: 'a1',
          tableType: 'editDelete',
          width: '200px',
          onEdit: (text, records) => onOpen({ update, form: records }),
          onDel: (text, records) => delData({ del, form: records })
        }
      ]
    }
  }
}
